import '../styles/index.scss';

window.tapeAmount = null;

$(() => {
	console.log("loaded!");
	

	let handleResize = (e) => {
		
		
		if ($(window).width() <= 1023)
		{
			$(".grow").css("height", ($("#mg").height()+30)+"px");
			$(".grow.border").css("height", ($("#mg").height())+"px");
		}
		else
		{
			$(".grow").css("height", ($("#mg").height()+50)+"px");		
			$(".grow.border").css("height", ($("#mg").height())+"px");
		}
		
		handleScroll();
	};
	
	
	let handleScroll = (e) => {
		let st = $(window).scrollTop();
		let sb =  $(window).scrollTop() + $(window).height();
		window.paratargets = $(".para");

		for (let i=0; i <  window.paratargets.length; i++)
		{
			let target = $(window.paratargets[i]);

			if (sb > target.offset().top && st < (target.offset().top + target.height()))
			{
				let para = (target.offset().top + (target.height()/2) - st) / $(window).height();


				let classes = target.attr("class");
				let rate = 100;

				if (classes.indexOf("rate-") != -1)
				{
					let cArr = classes.split(" ");

					for (let m=0; m<cArr.length; m++)
					{
						if (cArr[m].indexOf("rate-") != -1)
						{
							let aArr = cArr[m].split("-");


							//console.log("i "+i);
							//console.log("aArr[1] "+aArr[1]);
							rate = parseFloat(aArr[1]);

							break;
						}
					}
				}

				
				let transform = "";

				if (target.hasClass("reverse"))
				{
					//console.log("rate ", ((1*rate)*(para-0.5)));
					
					transform = "translate(0px, "+((1*rate)*(para-0.5))+"px)";
				}
				else
				{
					//console.log("rate ", ((-1*rate)*(para-0.5)));
					transform = "translate(0px, "+((-1*rate)*(para-0.5))+"px)";
				}
				
				if (target.hasClass('rotate-10'))
					transform += " rotate(10deg)";
				if (target.hasClass('rotate-12'))
					transform += " rotate(12deg)";
				else if (target.hasClass('rotate--20'))
					transform += " rotate(-21deg)";
				
				
				target.css("transform", transform);
			}

		}
		
		let tapeholders= $(".tape-holder");
		
		
		
		for (let i=0; i < tapeholders.length; i++)
		{
			let target = $(tapeholders[i]);

			if (sb > target.offset().top && st < (target.offset().top + target.height()))
			{
				let para = (target.offset().top + (target.height()/2) - st) / $(window).height();

				let amounts = window.tapeAmounts[i];
				
				
				let transform = "translate("+((-1*amounts.rateX)*(para-0.5))+"px, "+((-1*amounts.rateY)*(para-0.5))+"px) rotate("+amounts.rotate+"deg)";
				
				target.css("transform", transform);
			}

		}
		
		
	};
	
	$(window).on("scroll", handleScroll);
	
	$(window).on("resize", handleResize);
	handleResize();
	
	
	window.tapes = {};
	
	let getQueryVariable = (variable)=> {
		var query = window.location.search.substring(1);
		var vars = query.split('&');
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split('=');
			if (decodeURIComponent(pair[0]) == variable) {
				return decodeURIComponent(pair[1]);
			}
		}
		
		return null;
	};
	
	
	
	window.tapes = [];
	
	function iOS() {
	  return [
		'iPad Simulator',
		'iPhone Simulator',
		'iPod Simulator',
		'iPad',
		'iPhone',
		'iPod'
	  ].includes(navigator.platform)
	  // iPad on iOS 13 detection
	  || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
	}
	
	let openModal = (tape_id) => {
		console.log("show tape", tape_id);
		
		let tape = null;
		
		for (let t=0; t<window.tapes.length; t++)
		{
			if (tape_id == window.tapes[t].id)
				tape = window.tapes[t];
		}
		
		
		if (tape != null)
		{
			$("#modal img.tape-img").attr("src", '/images/tapes/cassettes/330/'+tape.id+'.png');
			$("#modal img.tape-img").attr("src-set", '/images/tapes/cassettes/330/'+tape.id+'.png 330w'+
             				'/images/tapes/cassettes/660/'+tape.id+'.png 800w');
			
			
			
			$("#modal iframe").attr("src", tape.playlist);
			
			$("#modal a.share").attr("href", window.location.protocol+"//"+window.location.host+"/"+tape.id);
			
			if (! iOS())
			{
				document.body.style.top = `-${window.scrollY}px`;
				document.body.style.position = 'fixed';
			}
			else
				console.log("ios");
			
		
			$("#modalOutter").addClass("show");
		
			$("#mg").addClass("over");
		
			setTimeout(()=>{
				$("#modalOutter").addClass("active");
			}, 100);
		}
	};
	
	let closeModal = (tape) => {
		console.log("close modal");
		
		const scrollY = document.body.style.top;
		
		if (! iOS())
		{
			document.body.style.position = '';
			document.body.style.top = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		}
		
		$("#modalOutter").removeClass("active");
		
		$("#mg").removeClass("over");
		
		setTimeout(()=>{
			$("#modalOutter").removeClass("show");
			$("#modal iframe").attr("src", "");
		}, 600);
	};
	
	$("#modal a.share").on("click", (e)=>{
		
		let shareLink = $(e.currentTarget).attr("href");
		
		gtag('event', 'shareCopySite', {
		  'event_category' : shareLink
		});
		
		if (navigator.share) {
			navigator.share({
				url: shareLink
			}).then(() => console.log('Successful share'))
			.catch(error => console.log('Error sharing:', error));

		}
		else
		{
			$("#txt_copy").val(shareLink);
			$("#txt_copy")[0].select();

			document.execCommand('copy');
			
			$("#copied_tooltip").addClass("active");
			
			setTimeout(()=>{
				$("#copied_tooltip").removeClass("active");
			}, 3000);
		}
		
		e.preventDefault();
	});
	
	$("#modal .close").on("click", (e)=>{
		closeModal();
		e.preventDefault();
	});
    
	
	var jqxhr = $.getJSON( "/data/tapes.json?v=202102071008", (data) => { 
		window.tapes = data;
		$(".body .row").remove();
		
		// TODO: Add tapes
		
		let row = null;
		
		let counter = 2;
		
		for (let t=0; t<data.length; t++)
		{
		
			let mod = t % 3;
			
			if (mod == 0 || mod == 1 || row == null)
			{
				if (row != null)
					$("div.body").append(row);
				
				if (mod == 1)
					row = $('<div class="row"></div>');
				else
					row = $('<div class="row single"></div>');
			}
			
			let tape = data[t];
			
			let tapeHTML=$('<div class="tape">'+
					'<a href="/'+tape.id+'" data-tape="'+tape.id+'">'+
						'<div class="tape-holder"><img src="/images/tapes/cassettes/330/'+tape.id+'.png"'+
						'srcset="/images/tapes/cassettes/330/'+tape.id+'.png 330w'+
             				'/images/tapes/cassettes/660/'+tape.id+'.png 800w"'+
						' class="tape-img" alt="'+tape.name+'" sizes="(max-width: 452px) calc(100vw - 122px), 330px"></div>'+
						'<img src="/images/open-playlist_d@1x.png" alt="Open Playlist" class="btn-img desktop-only">'+
						'<img src="/images/open-playlist_m@1x.png" alt="Open Playlist" class="btn-img mobile-only">'+
					'</a>'+
				'</div>');
			
			row.append(tapeHTML);
		}
		
		if (row != null)
			row = $("div.body").append(row);
		
		
		$(".tape a").on("click", (e)=>{
			if (! $("#modal").hasClass("active"))
			{
				openModal($(e.currentTarget).attr("data-tape"));
			}
			e.preventDefault();
		});
		
		$("img").on("load", ()=>{
			handleResize();
		});
		
		let tapeholders= $(".tape-holder");
		window.tapeAmounts = [];
		
		for (let i=0; i < tapeholders.length; i++)
		{
			let rotate = Math.random()*20 - 10;
			let rateX = Math.random()*20 - 10;
			let rateY = Math.random()*15 + 10;
			
			window.tapeAmounts.push({
				rotate: rotate,
				rateX: rateX,
				rateY: rateY
				
			});
		}
		
		setTimeout(()=>{
			$("body").addClass("active");
			
			handleResize();
			
			let target_id = getQueryVariable("tape");
			
			if (target_id != null) {
				let target = $('a[data-tape="'+target_id+'"]');
				
				if (target.length > 0 )
				{
					target = $(target[0]);
					console.log(target.offset());
					var body = $("html, body");
					body.stop().animate({scrollTop: target.offset().top-100}, 500);
					
					setTimeout(()=>{
						openModal(target_id);
					}, 600);
				}
				else
				{
					console.log(target);
				}
			}
			
			
		}, 500);
		
		
		
		
	})
	.fail(() => {
		console.log("FAILED TO GET TAPES");
	});
	
	
});